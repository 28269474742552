import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./non-member-single.scss"

import { getCategories } from "../redux/api/apiAction"
import { selectCategory } from "../redux/registration/registrationAction"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import TabSingleInformation from "../containers/singleInformation/tabSingleInformation/tabSingleInformation"
import About from "../containers/singleInformation/about"
import Ticket from "../containers/singleInformation/ticket"
import Information from "../containers/singleInformation/information"
import Summary from "../containers/singleInformation/summary"

const NonMemberPage = () => {
    const dispatch = useDispatch()
    const { information } = useSelector(({ registrationReducer }) => registrationReducer)
    const { user } = useSelector(({ apiReducer }) => apiReducer)
    const [tabIndex, setTabIndex] = useState(0)

    if (user === null) {
        useEffect(() => {
            dispatch(getCategories())
                .then((categories) => {
                    dispatch(selectCategory(
                        categories, information.unionMember ? "UNION Member" : "Delegate"))
                })
        }, [])
    }


    const onSelect = index => {
        setTabIndex(index)
    }

    const prevTab = () => setTabIndex(prev => prev - 1);
    const nextTab = () => setTabIndex(prev => prev + 1);

    return (
        <Layout>
        <SEO title="Registration" />
    <div className={"col-10 mx-auto"}>
                
    
                <div className={"form-row"}>
                    <div className={"col-12"}>
                        <p>Registration is now closed</p>
                    </div>
                </div>
                
        </div>
        </Layout>
    )
}

export default NonMemberPage
